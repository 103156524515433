import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';
import SunEditor from 'suneditor-react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChatPage = () => {
    let admin = JSON.parse(sessionStorage.getItem("admin"));
    let emp = JSON.parse(sessionStorage.getItem("emp"));

    const userid = 1;
    const type = 'admin';
    const token = 'd83f8067106535063fb7a6aa';

    let saveDraftid = admin ? admin.user.userId : emp.user.userId;
    let saveDraftToken = admin ? admin.token : emp.token;
    let saveName = admin ? admin.user.name : emp.user.name;
    let Type = type == "admin" ? type : emp.user.isAdmin

    const { id, ticketnumber, parent_email, email, replyuserid, title, department } = useParams();
    let param = useParams();

    const [ticketDetails, setTicketDetails] = useState([]);
    const [searchMessage,setSearchMessage] = useState('')
    const [dataItem,setDataItem] = useState({})

    useEffect(() => {
        fetchTicketDetails();
    }, []);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const fetchTicketDetails = async () => {
        try {
            const response = await fetch(
                `https://authkey.io/api/support.php?user_id=${userid}&method=ticketdetail&type=${type}&token=${token}&id=${id}`
            );
            const result = await response.json();
            if (result.success) {
                console.log(result.data);
                setTicketDetails(result.data);
            } else {
                console.error('Error fetching ticket details');
            }
        } catch (error) {
            console.error('Error fetching ticket details:', error);
        }
    };

    const SearchTicketByDate = async () => {
        let param = {
            method: 'filter_ticket_details',
            date_from: dataItem.date_from,
            date_to: dataItem.date_to,
            user_id: saveDraftid,
            token: saveDraftToken,
            type: Type,
            ticket_id: id
        }
        console.log(param)
        try {
            let response = await fetch('https://console.authkey.io/api/support.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(param),
            });
            let result = await response.json()
            if(result.success){
                console.log(result.data)
                setTicketDetails(result.data)
    
            }
        } catch (error) {
         console.log(error)
        }
    }

    return (
        <>
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <div className="layout-page">
                        <div className="content-wrapper ">
                            <div className="container-xxl flex-grow-1 container-p-y">
                            <div className=" flex-fill">
                            <div className="row">
                <div className="col-lg-12 mb-4 order-0">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title text-primary">Filter</h5>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="fromDate">From Date</label>
                          <input
                            type="date"
                            id="date_from"
                            name="date_from"
                            value={dataItem.date_from}
                            onChange={onChange}
                            className="form-control"
                            placeholder="Select Date"
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="toDate">To Date</label>
                          <input
                            type="date"
                            id="date_to"
                            name="date_to"
                            value={dataItem.date_to}
                            onChange={onChange}
                            className="form-control"
                            placeholder="Select Date"
                          />
                        </div>
                        <div className="col-md-4 mb-3" style={{marginTop:"27px"}}>
                        <div className="input-group-append">
                              <button
                                type="button"
                                onClick={SearchTicketByDate}
                                className="btn btn-primary"
                              >
                                Search
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                          <div className='row'>
                            <div className="col-6">
                            <div className="card-header justify-content-between align-items-center d-flex">
                              <h5 className="card-title mb-0" style={{ fontSize: '15px' }}> <strong> Subject:</strong> {title} </h5>
                            </div>
                            </div>
                            <div className="col-6" style={{marginTop:"10px"}}>
                          {/* <label htmlFor="searchInput">Search</label> */}
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                            </div>
                            <input
                              type="text"
                              id="searchInput"
                              name="searchdata"
                              value={searchMessage}
                              onChange={(e) => setSearchMessage(e.target.value)}
                              className="form-control"
                              placeholder="Search Message"
                            />
                            {/* <div className="input-group-append">
                              <button
                                type="button"
                                onClick={''}
                                className="btn btn-primary"
                              >
                                Search
                              </button>
                            </div> */}
                          </div>
                        </div>
                            </div>
                            <hr className="m-0" />
                            <div className="card-body">
                              <div className="messaging">
                                <div className="inbox_msg">
                                  <div className="">
                                    <div className="message-container table-responsive">
                                      {ticketDetails ?
                                        <div className="">
                                          <div className="message-container">
                                            {
                                              Object.keys(ticketDetails).reverse().filter((x)=>{
                                                return searchMessage.toLowerCase() === '' ? ticketDetails[x] : ticketDetails[x].message.toLowerCase().includes(searchMessage);
                                              }).map((item, i) =>
                                                <>
                                                  {ticketDetails[item].update_by === 'client' ?
                                                    <div className="incoming_msg">
                                                      <div className="received_msg">
                                                        <div className="received_withd_msg">
                                                          <span>{email.split('@')[0]}</span>
                                                          <p dangerouslySetInnerHTML={{ __html: ticketDetails[item].message }} className="mt-1"></p>
                                                          <span className="time_date text-right">{ticketDetails[item].createdDate}</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    :
                                                    null
                                                  }

                                                  {ticketDetails[item].update_by === 'emp' || ticketDetails[item].update_by === 'admin' ?
                                                    <div className="outgoing_msg">
                                                      <div className="sent_msg">
                                                        <span>{saveName}</span>
                                                        <p dangerouslySetInnerHTML={{ __html: ticketDetails[item].message }} className="text-white"></p>
                                                        <span className="time_date"> {ticketDetails[item].createdDate} </span>
                                                      </div>
                                                    </div>
                                                    :
                                                    null
                                                  }
                                                </>
                                              )
                                            }
                                          </div>
                                        </div>
                                        :
                                        null
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                                </div>
                                </div>
                                </div>
                                </div>
                            </div>
           
           
        </>
    );
}

export default ChatPage;
