import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPowerOff, faBars } from '@fortawesome/free-solid-svg-icons'
import { emp_name, name, type } from './common/AdminAuth';
import SideMenu from './SideMenu';
import Login from './Login';

const TopMenu = () => {
  let client = JSON.parse(sessionStorage.getItem("client"))
  
  const navigate = useNavigate()

  const [sideMenu,setSideMenu] = useState(false)

  const sideBartoggle = () => {
    let menubar = document.getElementById("mobile-menu");
    menubar.classList.add('layout-menu-expanded');
  }

  // const [isExpanded, setIsExpanded] = useState(false);

  // const handleToggle = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const Logout = () =>{
    sessionStorage.clear();
    navigate("/")
  }


  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
      id="layout-navbar"
    >
      <div className="d-flex align-items-center justify-content-between">
        <FontAwesomeIcon style={{cursor:"pointer"}} onClick={sideBartoggle} icon={faBars} />
      </div>

      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div className="menu-inner-shadow"></div>
        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <span className='mr-2'>Welcome: <strong>{name ?? client.user.name}</strong></span>
          <li className="nav-item navbar-dropdown dropdown-user border-left dropdown">
            <div style={{cursor:"pointer"}} className="nav-link dropdown-toggle pl-3 hide-arrow">
              <FontAwesomeIcon onClick={Logout} icon={faPowerOff} />
            </div>
          </li>

        </ul>
        {sideMenu && <SideMenu />}
      </div>
    </nav>
  );
};

export default TopMenu;