let admin =  JSON.parse(sessionStorage.getItem("admin"))
let Employee = JSON.parse(sessionStorage.getItem("emp"))
// let Employee = JSON.parse(sessionStorage.getItem("emp"))
// console.log(admin);
let userid, token, name, type;

if (sessionStorage.getItem("admin") != null || sessionStorage.getItem("emp") != null) {
    userid = admin?.user?.userId ? admin?.user?.userId : Employee?.user?.userId ;
    name = admin?.user?.name ? admin?.user?.name : Employee?.user?.name ;
    token = admin?.token ? admin?.token : Employee?.token ;
    type = admin?.user?.isAdmin ? admin?.user?.isAdmin : Employee?.user?.isAdmin  ;
} else {
    //console.log('admin Null');
}


const userloginDetails = () => {
    const UserDetails = JSON.parse(localStorage.getItem("admin")) || JSON.parse(localStorage.getItem("emp"));
    return UserDetails;

}

export { userid, token, name, type, userloginDetails}
