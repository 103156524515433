import React from 'react';
import './App.css';
import './assets/css/core.css';
import './assets/css/theme-default.css';
import './assets/css/demo.css';
import { BrowserRouter as Router, Route, Routes, json } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import Login from './Components/Login';
import TicketList from './Components/TicketList';
import ViewTicket from './Components/ViewTicket';
import ErrorPage from './Components/common/ErrorPage';
import { type } from './Components/common/AdminAuth';
import QuickReply from './Components/QuickReply';
import QuickReplyList from './Components/QuickReplyList';
import ChatPage from './Components/ChatPage';
import ClientTicketList from './Components/ClientTicketList';
import CreateTicket from './Components/CreateTicket';
import ViewClientTicket from './Components/ViewClientTicket';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/dashboard" element={<Dashboard/>} />
        {type === "admin" || type === "emp" ? (
            <>
            
            <Route exact path="/user/ticketlist" element={<TicketList />} />
            <Route
              exact
              path="/user/viewticket/:id/:ticketnumber/:parent_email/:title/:email/:department/:replyuserid"
              element={<ViewTicket />}
            />
            <Route exact path="/user/quick_reply" element={<QuickReply/>} />
            <Route exact path="/user/quick_reply_list" element={<QuickReplyList/>} />
            <Route
             exact
             path="/user/chat/:id/:ticketnumber/:parent_email/:title/:email/:department/:replyuserid"
             element={<ChatPage/>} />
            </> 
        ) : <>
          {/* <Route exact path="/client/ticket_list" element={<ClientTicketList/>} />
          <Route exact path="/client/create_ticket" element={<CreateTicket/>} />
          <Route exact path="/client/ticket/:id/:tiketid/:status/:userid" element={<ViewClientTicket/>} /> */}
        </>}
           
        </Routes>
      </Router>
    </div>
  );
}

export default App;
