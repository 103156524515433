import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
// import { URL } from "../component/common/Url";

const Login = () => {
    const navigate = useNavigate()

    const [dataItem,setDataItem] = useState({})
    const [successMessage,setSuccessMessage] = useState({
      show: false,
      loginMessage: "",
      bgColor: "alert alert-danger",
    })
    const [showPassword,setShowPassword] = useState(false)

    const onChange = (e) =>{
       setDataItem({...dataItem,[e.target.name]:e.target.value})
    }

    const Login =async () =>{
        var url = document.location.href;
        let data = {
            username: dataItem.username,
            password: dataItem.password
        }
      let response = await fetch("https://authkey.io/api/login.php", {
        method: "post",
        headers: {
          "content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
      let result = await response.json()
      if(result.success == true){
        console.log(result)
        setSuccessMessage({
            show: true,
            loginMessage: "Success",
            bgColor: "alert alert-success",
        })
           if (result.user.isAdmin === "admin") {
            sessionStorage.setItem("admin", JSON.stringify(result));
            setTimeout(function () {
              window.location = "/dashboard";
            }, 1000);
          }
          else if (result.user.isAdmin === "emp") {
            sessionStorage.setItem("emp", JSON.stringify(result));
            setTimeout(function () {
              window.location = "/dashboard";
            }, 1000);
          }
          // else if (result.user.isAdmin === "client") {
          //   sessionStorage.setItem("client", JSON.stringify(result));
          //   setTimeout(function () {
          //     window.location = "/dashboard";
          //   }, 1000);
          // }
    }
    else {
        setSuccessMessage({
          show: true,
          loginMessage: result.message,
          bgColor: "alert alert-danger",
        });
      }

}

const PasswordHandler = () =>{
  setShowPassword(!showPassword)
}



  return (
    <div className="container">
        <div className="authentication-wrapper mt-5 authentication-basic container-p-y">
          <div className="authentication-inner">
            <div className="row justify-content-center">
              <div className="col-md-5">
                <div className="card">
                  <div className="card-body">
                    <div className="app-brand mb-5 justify-content-center">
                      <span className="app-brand-logo demo"></span>
                      <span className="app-brand-text demo text-body fw-bolder">
                        {" "}
                        Login
                      </span>
                    </div>

                    {successMessage.show ? (
                      <div className={successMessage.bgColor}>
                        {successMessage.loginMessage}
                      </div>
                    ) : null}

                    <h4 className="mb-2">Welcome to Support Ticket! 👋</h4>
                    <p className="mb-4">
                      Please log-in to your account 
                    </p>

                    <div className="mb-3">
                      <label for="email" className="form-label">
                        Email or Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="username"
                        value={dataItem.username}
                        placeholder="Enter your email or username"
                        autofocus
                        onChange={onChange}
                      />
                    </div>
                    <div className="mb-3 form-password-toggle">
                      <div className="d-flex justify-content-between">
                        <label className="form-label" for="password">
                          Password
                        </label>
                      </div>

                      <div className="input-group input-group-merge">
                        <input
                          type={showPassword ? "text" : "password" }
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="Enter Password"
                          aria-describedby="password"
                          value={dataItem.password}
                          onChange={onChange}
                        />
                        <span className="input-group-text cursor-pointer">
                          <FontAwesomeIcon
                            onClick={PasswordHandler}
                            icon={showPassword ? faEyeSlash : faEye}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="mb-3">
                      <button
                        className="btn btn-primary d-grid w-100"
                        onClick={Login}
                        type="button"
                      >
                        Sign in
                      </button>
                    </div>

                    <p className="text-center">
                      <span> New on our SMPP-SMS Platform </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Login
