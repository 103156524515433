import React, { useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';
import { Link, json, useNavigate, useParams } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { buttonList } from 'suneditor-react';
import { url } from '../Url';
import { emp_token, emp_userid, name, token, type, userid } from './common/AdminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSearch, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import AlertBox from '../Components/AlertBox';
import QuickReply from './QuickReply';
import { FadeLoader } from 'react-spinners';

const ViewTicket = () => {
  let navigate = useNavigate()

  const [ticketDetails, setTicketDetails] = useState({});
  const [message, setMessage] = useState('');
  const [undoButton, setUndoButton] = useState(false)
  const [disableSendButton, setDisableSendButton] = useState(false)
  const [undoState, setUndoState] = useState(true)
  const [abortController, setAbortController] = useState(null);
  const [showNotePopup, setShowNotePopup] = useState(false);
  const [notes, setNotes] = useState('')
  const [notesData, setNotesData] = useState([])
  const [notesID, setNotesID] = useState('')
  const [successDraft, setSuccessDraft] = useState(false)
  const { id, ticketnumber, parent_email, email, replyuserid, title, department } = useParams();
  const [alert, setAlert] = useState(null);
  const [quickReply, setQuickReply] = useState([])
  const [searchQuickList, setSearchQuickList] = useState('')
  const [searchNotes, setSearchNotes] = useState('')
  const [typing, setTyping] = useState(false)
  const [searchMessage, setSearchMessage] = useState('')
  const [toggleButton, setToggleButton] = useState(true)
  const [dataItem,setDataItem] = useState({})
  const [loading,setLoading]=useState(false)
  const [emptyData,setEmptyData] = useState(false)

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };


  useEffect(() => {
    fetchTicketDetails();
    retrieveDraftData();
    fetchNotesData();
    ViewQuickReply();
  }, []);

  const fetchTicketDetails = async () => {
    try {
      const response = await fetch(
        `https://authkey.io/api/support.php?user_id=${userid}&method=ticketdetail&type=${type}&token=${token}&id=${id}`
      );
      const result = await response.json();
      if (result.success) {
        setTicketDetails(result.data);
      } else {
        console.error('Error fetching ticket details');
      }
    } catch (error) {
      console.error('Error fetching ticket details:', error);
    }
  };

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value })
}

  const handleMessageChange = (content) => {
    if (content === "<p><br></p>") {
      setMessage('');
      return
    }
    setMessage(content)
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value)
  }

  const replyMessage = async () => {
    setDisableSendButton(true); // Disable the button immediately
    setUndoButton(true);

    // Create a new AbortController
    const controller = new AbortController();
    setAbortController(controller);

    try {
      // Start a 10-second timer
      await new Promise(resolve => setTimeout(resolve, 3000));

      const reply = {
        user_id: userid,
        type: type,
        message: message,
        id: id,
        replyuserid: replyuserid,
        method: 'reply',
        token: token,
      };

      if (undoState === true) {
        const response = await fetch('https://console.authkey.io/api/support.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reply),
          signal: controller.signal, // Pass the signal to the fetch call
        });

        const result = await response.json();
        if (result.success === true) {
          DeleteDraft();
          fetchTicketDetails();
          setMessage('')
        }
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch request aborted');
      } else {
        console.error('Error replying to message:', error);
      }
    } finally {
      setDisableSendButton(false);
      setUndoButton(false)
      // Enable the button after fetch completes or fails
    }
  };

  const handleClickCancel = () => {
    if (abortController) {
      abortController.abort();
      setDisableSendButton(false)
      setUndoButton(false) // Abort the fetch request
    }
  };

  const saveDraft = async () => {
    let data = {
      content: message,
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "save"
    }
    try {
      let api = await fetch("https://console.authkey.io/api/draft.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      showAlert("success", "Draft Save Successfully")
      if (api.success) {

        console.log("draft Save Successfully")

      }
      else {
        console.log("data does not save to draft")
      }
    } catch (error) {
      console.log(error)
    }
  }


  const retrieveDraftData = async () => {
    let data = {
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "retrieveid"
    }
    try {
      let retrieveApi = await fetch("https://console.authkey.io/api/draft.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      let response = await retrieveApi.json()
      if (response.success == true) {
        setMessage(response.data[0]?.content)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const DeleteDraft = async () => {
    let data = {
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "delete"
    }
    try {
      let deleteApi = await fetch("https://console.authkey.io/api/draft.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      if (deleteApi.success == true) {
        console.log("data Delete from Draft")
      }
      else {
        console.log("data should not delete")
      }
    } catch (error) {
      console.log(error)
    }
  }

  // To fetch Notes
  const fetchNotesData = async () => {
    let data = {
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "retrieve"
    }
    try {
      let fetchNotes = await fetch("https://console.authkey.io/api/notes.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data)
      })
      let resData = await fetchNotes.json()
      if (resData.success) {
        // console.log(resData.data)
        setNotesData(resData.data)
      }
    } catch (error) {

    }
  }

  // To Save Notes Data
  const saveNotes = async () => {
    let data = {
      content: notes,
      ticket_id: id,
      user_id: userid,
      emp_name: name,
      ret_user_id: replyuserid,
      token: token,
      method: "create"
    }
    try {
      let SaveNotes = await fetch("https://console.authkey.io/api/notes.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      setNotes('')
      fetchNotesData();
      if (SaveNotes.success) {
        console.log("Notes Create Successfully !")
      }
      else {
        console.log("Notes Does not Created")
      }
    } catch (error) {
      console.log(error)
    }
  }



  // To view Notes in update Popup
  const ViewNotes = async (notes_id) => {
    let data = {
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "retrieveid",
      id: notes_id
    }
    try {
      let retrieveData = await fetch("https://console.authkey.io/api/notes.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data)
      })
      let resData = await retrieveData.json()
      if (resData.success) {
        // console.log(resData.data[0].content)
        setNotesID(resData.data[0].id)
        setNotes(resData.data[0].content)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // To Delete Notes
  const DeleteNotes = async (notes_id) => {
    let data = {
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "delete",
      id: notes_id
    }
    try {
      let deleteNotes = await fetch("https://console.authkey.io/api/notes.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data)
      })
      fetchNotesData();
      if (deleteNotes.success) {
        console.log("Notes Deleted Successfully")

      }
    } catch (error) {

    }
  }

  // To Update Notes
  const UpdateNotes = async () => {
    let data = {
      content: notes,
      ticket_id: id,
      user_id: userid,
      ret_user_id: replyuserid,
      token: token,
      method: "update",
      id: notesID
    }
    try {
      let updateNotes = await fetch("https://console.authkey.io/api/notes.php", {
        method: "post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data)
      })
      let responseData = await updateNotes.json()

      if (responseData.success) {
        //  console.log(responseData)
        setNotes('')
        setNotesID('')
        fetchNotesData();

      }
    } catch (error) {
      console.log(error)
    }
  }

  // To Fetch Quick Reply Data
  const ViewQuickReply = async () => {
    let Param = {
      method: "retrieve_all",
      user_id: userid,
      token: token
    }
    try {
      let retrieveApi = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(Param)
      })
      let responseData = await retrieveApi.json()
      if (responseData.success) {
        console.log(responseData.data)
        setQuickReply(responseData.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // To insert quick Reply data in message Box
  const QuickReplyMessage = async (id) => {
    let param = {
      method: "retrieveid",
      user_id: userid,
      token: token,
      id: id
    }
    try {
      let retrieveApi = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(param)
      })
      let responseData = await retrieveApi.json()
      if (responseData.success) {
        // console.log(responseData.data[0].content)
        setMessage(responseData.data[0].content)
      }
    } catch (error) {
      console.log(error)
    }
  }
  console.log(quickReply)

  console.log(message)

  const SearchTicketByDate = async () => {
    setLoading(true)
    let param = {
        method: 'filter_ticket_details',
        date_from: dataItem.date_from,
        date_to: dataItem.date_to,
        user_id: userid,
        token: token,
        type: type,
        ticket_id: id
    }
    console.log(param)
    try {
        let response = await fetch('https://console.authkey.io/api/support.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(param),
        });
        let result = await response.json()
        if(result.success){
            console.log(result.data)
            setTicketDetails(result.data)
            setLoading(false)
        }
        else {
          setEmptyData(true)
        }
    } catch (error) {
     console.log(error)
    }
}


  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        {showNotePopup && (
          <div className="popup-container"  >
            <div className="popup-content" style={{ position: "top" }}>
              <div className='row' style={{ marginTop: "-10px" }}>
                <div className='col-12'>
                  <FontAwesomeIcon style={{ marginLeft: "95vh", cursor: "pointer" }} onClick={() => setShowNotePopup(false)} icon={faTimes} />
                </div>
              </div>
              <div className='row' style={{ marginTop: "10px" }}>
                <div className='col-12'>
                  <textarea className="orangeTextArea" name='textArea' value={notes} onChange={handleNotesChange} placeholder='Type Here ...'></textarea>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <button className="btn mt-1 btn-secondary btn-sm text-right" style={{ border: "hidden", borderRadius: "5px 5px 5px 5px", width: "50px", cursor: 'pointer', marginTop: "10px" }} onClick={notesID == '' ? saveNotes : UpdateNotes}>Save</button>
                </div>
                <div className='col-6'>
                  <input
                    type="text"
                    id="searchInput"
                    name="searchQuickdata"
                    value={searchNotes}
                    onChange={(e) => setSearchNotes(e.target.value)}
                    className="form-control"
                    placeholder="Search Notes"
                  />
                </div>
              </div>

              <div className='row' style={{ marginTop: "20px" }}>
                <div className='col-12'>
                  <div className="table-responsive" style={{ maxHeight: "270px", overflowY: "auto" }}>
                    <table className="styled-table">
                      <thead>
                        <tr>
                          <th style={{ width: "10px", fontSize: "13px" }}>Action</th>
                          <th style={{ fontSize: "13px" }}>Notes</th>
                          <th style={{ fontSize: "13px" }}>Created Date</th>
                          <th style={{ fontSize: "13px" }}>Created By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {notesData.filter((x) => {
                          return searchNotes.toLowerCase() === '' ? x : x.content.toLowerCase().includes(searchNotes);
                        }).map((items) => {
                          return (
                            <tr key={items.id}>
                              <td>
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => ViewNotes(items.id)}
                                  icon={faEye}
                                />
                                <FontAwesomeIcon
                                  style={{ cursor: "pointer", marginLeft: "7px" }}
                                  onClick={() => DeleteNotes(items.id)}
                                  icon={faTrash}
                                />
                              </td>
                              <td>{items.content}</td>
                              <td>{items.created}</td>
                              <td>{items.created_by_name}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}
        <div className="layout-container">
          <SideMenu />
          <div className="layout-page">
            <TopMenu />
            <div className="container">
              <div className="box">
                <h4 style={{ marginTop: '50px', marginBottom: '30px' }}>Support Management</h4>
              </div>
              <h5 style={{ marginLeft: "5px", color: "blue" }}>Ticket Details</h5>
              <div className="row">
                <div className="col-lg-12 mb-4 order-0">
                  <div className="card">
                    <div className="card-header justify-content-between align-items-center d-flex">
                      <h5 className="card-title mb-0" style={{ fontSize: '15px' }}> <strong> Ticket ID:</strong>{ticketnumber}  </h5>
                      <h5 className="card-title mb-0" style={{ fontSize: '15px' }}> <strong> User Name:</strong> {email} </h5>
                      <h5 className="card-title mb-0" style={{ fontSize: '15px' }}> <strong> Department:</strong> {department} </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-4 order-0">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title text-primary">Filter</h5>
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <label htmlFor="fromDate">From Date</label>
                          <input
                            type="date"
                            id="date_from"
                            name="date_from"
                            value={dataItem.date_from}
                            onChange={onChange}
                            className="form-control"
                            placeholder="Select Date"
                          />
                        </div>
                        <div className="col-md-4 mb-3">
                          <label htmlFor="toDate">To Date</label>
                          <input
                            type="date"
                            id="date_to"
                            name="date_to"
                            value={dataItem.date_to}
                            onChange={onChange}
                            className="form-control"
                            placeholder="Select Date"
                          />
                        </div>
                        <div className="col-md-4 mb-3" style={{marginTop:"27px"}}>
                        <div className="input-group-append">
                              <button
                                type="button"
                                onClick={SearchTicketByDate}
                                className="btn btn-primary"
                              >
                                Search
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {alert && (
                <AlertBox
                  type={alert.type}
                  message={alert.message}
                  onClose={() => setAlert(null)}
                />
              )}
              <div className="row" >
                <div className="col-12 col-lg-12 col-xxl-12 d-flex">
                  <div className=" flex-fill">
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className='row'>
                            <div className="col-6">
                            <div className="card-header justify-content-between align-items-center d-flex">
                              <h5 className="card-title mb-0" style={{ fontSize: '15px' }}> <strong> Subject:</strong> {title} </h5>
                            </div>
                            </div>
                            <div className="col-6" style={{marginTop:"10px"}}>
                          {/* <label htmlFor="searchInput">Search</label> */}
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                            </div>
                            <input
                              type="text"
                              id="searchInput"
                              name="searchdata"
                              value={searchMessage}
                              onChange={(e) => setSearchMessage(e.target.value)}
                              className="form-control"
                              placeholder="Search Message"
                            />
                            {/* <div className="input-group-append">
                              <button
                                type="button"
                                onClick={''}
                                className="btn btn-primary"
                              >
                                Search
                              </button>
                            </div> */}
                          </div>
                        </div>
                            </div>
                            <hr className="m-0" />
                            <div className="card-body">
                              <div className="messaging">
                                <div className="inbox_msg">
                                  <div className="">
                                    <div className="message-container table-responsive">
                                      {ticketDetails.length > 0 ?
                                     (
                                      <>
                                      {emptyData ? <h3 style={{marginLeft:"30vh"}}>Record not Found !</h3> :
                                        <div className="">
                                        <div className="message-container" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                                        {
                                          Object.keys(ticketDetails).reverse().filter((x) => {
                                            return searchMessage.toLowerCase() === '' ? ticketDetails[x] : ticketDetails[x].message.toLowerCase().includes(searchMessage);
                                          }).map((item, i) =>
                                            <>
                                              {ticketDetails[item].update_by === 'client' ?
                                                <div className="incoming_msg">
                                                  <div className="received_msg">
                                                    <div className="received_withd_msg">
                                                      <span>{email.split('@')[0]}</span>
                                                      <p dangerouslySetInnerHTML={{ __html: ticketDetails[item].message }} className="mt-1"></p>
                                                      <span className="time_date text-right">{ticketDetails[item].createdDate}</span>
                                                    </div>
                                                  </div>
                                                </div>
                                                :
                                                null
                                              }
  
                                              {ticketDetails[item].update_by === 'emp' || ticketDetails[item].update_by === 'admin' ?
                                                <div className="outgoing_msg">
                                                  <div className="sent_msg">
                                                    <span>{name}</span>
                                                    <p dangerouslySetInnerHTML={{ __html: ticketDetails[item].message }} className="text-white"></p>
                                                    <span className="time_date"> {ticketDetails[item].createdDate} </span>
                                                  </div>
                                                </div>
                                                :
                                                null
                                              }
                                            </>
                                          )
                                        }
                                      </div>
                                  </div> }
                                   </>
                                     )  
                                        :
                                       null
                                      }
                                    </div>
                                    <div className="filter-option">
                                      <div className='row'>
                                        <div className='col-6'>
                                          <h6 style={{ marginTop: "20px" }} className="card-subtitle text-muted">Reply Message</h6>                                         
                                        </div>
                                        <div className='col-6 d-flex align-items-center justify-content-end' style={{ marginTop: "10px" }}>
                                          <a className="link-button" href={`/user/chat/${id}/${ticketnumber}/${parent_email}/${title}/${email}/${department}/${userid}`}>View Messages</a>
                                        </div>
                                      </div>

                                      <div className="row" style={{ marginTop: '10px' }}>
                                        <div className="col-md-12">
                                          <SunEditor
                                            height="300"
                                            setContents={message}
                                            onChange={handleMessageChange}
                                            setOptions={{
                                              buttonList: buttonList.complex,
                                            }}
                                            placeholder="Please type here..."
                                          />
                                        </div>
                                        <div className="col-md-12 text-right">
                                          <input
                                            style={{ marginRight: "5px", backgroundColor: "grey" }}
                                            type="button"
                                            onClick={() => setShowNotePopup(true)}
                                            value="Notes"
                                            className="btn mt-1 btn-primary btn-sm ml-auto text-right"
                                            disabled={disableSendButton}
                                          />
                                          <input
                                            style={{ marginRight: "5px", backgroundColor: "#4CAF50" }}
                                            type="button"
                                            onClick={saveDraft}
                                            value="Save Draft"
                                            className="btn mt-1 btn-primary btn-sm ml-auto text-right"
                                            disabled={disableSendButton}
                                          />
                                          {undoButton && (
                                            <input
                                              style={{ marginRight: "5px", backgroundColor: "#FF2500" }}
                                              type="button"
                                              onClick={handleClickCancel}
                                              value="Undo"
                                              className="btn mt-1 btn-secondary btn-sm text-right"
                                            // disabled = {undoButton}
                                            />
                                          )}

                                          <input
                                            style={{ marginRight: "10px" }}
                                            type="button"
                                            onClick={replyMessage}
                                            value="Send"
                                            className="btn mt-1 btn-primary btn-sm ml-auto text-right"
                                            disabled={disableSendButton}
                                          />
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-4 order-1">
                    <div className="card" style={{ width: "53vh" }}>
                      <div className="card-header">
                        <div className='row'>
                          <div className='col-6' style={{ marginTop: "7px" }}>
                            <h5 style={{ cursor: "pointer", fontSize: "15px" }} className="card-title mb-0"><b>Quick Reply List</b></h5>
                          </div>
                          <div className='col-6'>
                            <input
                              type="text"
                              id="searchInput"
                              name="searchQuickdata"
                              value={searchQuickList}
                              onChange={(e) => setSearchQuickList(e.target.value)}
                              className="form-control"
                              placeholder="Enter Title"
                            />
                          </div>
                        </div>
                      </div>
                     <div className="card-body">
                        <div className="table-responsive">
                          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Created</th>
                                </tr>
                              </thead>
                              <tbody>
                                {quickReply.filter((x) => {
                                  return searchQuickList.toLowerCase() === '' ? x : x.title.toLowerCase().includes(searchQuickList);
                                }).map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ cursor: "pointer" }} onClick={() => QuickReplyMessage(item.id)}>{item.title}</td>
                                    <td>{item.created_by_name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default ViewTicket;
