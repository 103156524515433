import React, { useEffect, useState } from 'react';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertBox from './AlertBox';
import { faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { token, userid } from './common/AdminAuth';

const QuickReplyList = () => {
  const [QuickList, setQuickList] = useState([]);
  const [quickReplyPopup, setQuickReplyPopup] = useState(false);
  const [data, setData] = useState({});
  const [alert, setAlert] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  useEffect(() => {
    fetchQuickReplyList();
  }, []);

  const fetchQuickReplyList = async () => {
    let Param = {
      method: "retrieve",
      user_id: userid,
      token: token
    };
    try {
      let retrieveApi = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(Param)
      });
      let responseData = await retrieveApi.json();
      if (responseData.success) {
        console.log(responseData.data);
        setQuickList(responseData.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = QuickList.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(QuickList.length / recordsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const showAlert = (type, message) => {
    setAlert({ type, message });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };

  const QuickReplyData = async (id) => {
    setQuickReplyPopup(true);
    let param = {
      method: "retrieveid",
      user_id: userid,
      token: token,
      id: id
    };
    try {
      let api = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(param)
      });
      let response = await api.json();
      if (response.success) {
        console.log(response.data[0]);
        setData(response.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const isValid = () => {
    if (!data.title || data.title.trim() === "") {
      showAlert("error", "Please Enter Title");
      return false;
    }
    if (!data.content || data.content.trim() === "") {
      showAlert("error", "Please Enter Description");
      return false;
    }
    return true;
  };

  const UpdateData = async (e) => {
    e.preventDefault();
    if (isValid()) {
      let param = {
        content: data.content,
        title: data.title,
        view_by: data.view_by,
        method: "update",
        user_id: userid,
        token: token,
        id: data.id
      };
      try {
        let api = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
          method: "Post",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(param)
        });
        if (api.ok) {
          console.log("Data Updated Successfully");
          setQuickReplyPopup(false);
          fetchQuickReplyList();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const DeleteData = async (id) => {
    let Param = {
      method: "delete",
      user_id: userid,
      token: token,
      id: id
    };
    try {
      let api = await fetch("https://console.authkey.io//api/support_quick_reply.php", {
        method: "Post",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(Param)
      });
      if (api.ok) {
        console.log("Data Deleted Successfully");
        setQuickReplyPopup(false);
        fetchQuickReplyList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <SideMenu />
          <div className="layout-page">
            <TopMenu />
            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <p className="demo-inline-spacing text-right ">
                  <Link className="btn btn-primary me-1" to="/user/quick_reply">
                    <FontAwesomeIcon icon={faPlus} /> Add Quick Reply
                  </Link>
                </p>
                <div className="row justify-content-center">
                  <div className="">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Action</th>
                          <th>Title</th>
                          <th>Content</th>
                          <th>View By</th>
                          <th>Created</th>
                          <th>Created By</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentRecords.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <div className="dropdown">
                                <span
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-three-dots-vertical"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </span>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <a
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => QuickReplyData(item.id)}
                                  >
                                    <FontAwesomeIcon icon={faTimes} /> Update
                                  </a>
                                  <a
                                    className="dropdown-item"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => DeleteData(item.id)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>{item.title}</td>
                            <td>{item.content.split('.')[0]}</td>
                            <td>{item.view_by === 1 || item.view_by === 0 ? <p>Public</p> : <p>Self</p>}</td>
                            <td>{item.created}</td>
                            <td>{item.created_by_name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ul className="pagination">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => handlePagination(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                    {quickReplyPopup && (
                      <div className="popup">
                        <div className="assign-popup-content">
                          <form>
                            <div className="form-group col-md-12">
                              <h5 className="popupHeader">Edit : {data.title}</h5>
                            </div>
                            <div className="overflowblocks">
                              {alert && (
                                <AlertBox
                                  type={alert.type}
                                  message={alert.message}
                                  onClose={() => setAlert(null)}
                                />
                              )}

                              <div className="form-group">
                                <label htmlFor="text">Title</label>
                                <input type="text" className="form-control" name='title' value={data.title || ''} onChange={handleChange} placeholder="Enter Title" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="content">Description</label>
                                <textarea className="form-control" name='content' value={data.content || ''} onChange={handleChange} placeholder="Enter Content" />
                              </div>
                              <div className="form-group">
                                <label htmlFor="view_by">View By</label>
                                <select name='view_by' value={data.view_by || ''} onChange={handleChange} className="form-control">
                                  <option value="">Select</option>
                                  <option value="1">Public</option>
                                  <option value="0">Self</option>
                                </select>
                              </div>
                              <button type="submit" onClick={UpdateData} className="btn btn-primary me-1">Update</button>
                              <button type="submit" onClick={() => setQuickReplyPopup(false)} className="btn btn-primary">Close</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickReplyList;
